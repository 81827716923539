<template>
  <div>
    <b-card>
      <b-card-header>
        <h2>
          <feather-icon icon="LayersIcon" />
          {{ $t("AppHelpDesk.users.contactDetails") }}
        </h2>
      </b-card-header>
      <b-card-body>
        <b-row>
          <b-col
            cols="12"
            md="6"
          >
            <b-form-group
              :label="$t('AppHelpDesk.users.userName')"
              label-for="userName"
            >
              <b-form-input
                id="userName"
                v-model="contact.nombreUsuario"
                disabled
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="3"
          >
            <b-form-group
              :label="$t('AppHelpDesk.users.employeeCode')"
              label-for="employeeCode"
            >
              <b-form-input
                id="employeeCode"
                v-model="contact.codigo"
                disabled
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col
            cols="12"
            md="9"
          >
            <b-form-group
              :label="$t('AppHelpDesk.users.fullName')"
              label-for="fullName"
            >
              <b-form-input
                id="fullName"
                v-model="contact.nombreCompleto"
                disabled
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col
            cols="12"
            md="6"
          >
            <b-form-group
              :label="$t('AppHelpDesk.users.email')"
              label-for="email"
            >
              <b-form-input
                id="email"
                v-model="contact.correo"
                disabled
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col
            cols="12"
            md="3"
          >
            <b-form-group
              :label="$t('AppHelpDesk.users.userPhone')"
              label-for="userPhone"
            >
              <b-form-input
                id="userPhone"
                v-model="contact.telefonoUsuario"
                disabled
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="3"
          >
            <b-form-group
              :label="$t('AppHelpDesk.users.homePhone')"
              label-for="homePhone"
            >
              <b-form-input
                id="homePhone"
                v-model="contact.telefonoCasa"
                disabled
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="3"
          >
            <b-form-group
              :label="$t('AppHelpDesk.users.cellPhone')"
              label-for="cellPhone"
            >
              <b-form-input
                id="cellPhone"
                v-model="contact.telefonoCelular"
                disabled
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col
            cols="12"
            md="6"
          >
            <b-form-group
              :label="$t('AppHelpDesk.users.employeeDepartment')"
              label-for="employeeDepartment"
            >
              <b-form-input
                id="employeeDepartment"
                v-model="contact.departamentoNombre"
                disabled
              />
            </b-form-group>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardBody,
  BRow,
  BCol,
  BFormInput,
  BFormGroup,
} from 'bootstrap-vue'
import helpDeskService from '@/services/helpDesk.service'
import router from '@/router'
import { ref } from '@vue/composition-api'

export default {
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BRow,
    BCol,
    BFormInput,
    BFormGroup,
  },
  setup() {
    const contact = ref({
      usuarioActivoId: 0,
      codigo: '',
      nombreUsuario: '',
      nombreCompleto: '',
      correo: '',
      telefonoUsuario: '',
      telefonoCasa: '',
      telefonoCelular: '',
      departamentoNombre: '',
    })
    const {
      fetchContactUser,
    } = helpDeskService()
    fetchContactUser(router.currentRoute.params.usuarioActivoId, data => {
      contact.value = data
    })
    return {
      contact,
    }
  },
}

</script>
